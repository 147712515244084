<template>
  <div>
    <div class="mb-4">
      <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
    </div>
    <div class="table-container js-table-arrow">
      <table class="stockTable">
        <thead>
          <tr>
            <th v-if="!eventId">Мероприятие</th>
            <th>Получатель</th>
            <th>Статус</th>
            <th>Дата отправления</th>
            <th>Плановая дата ответа</th>
            <th>Текст запроса</th>
            <th>Запрошено</th>
            <th></th>
          </tr>
          <tr>
            <th class="filter" v-if="!eventId">
              <FilterInput
                type="select2"
                :select2-options="eventList"
                :select2-settings="select2SettingsMulti"
                v-model="filter.eventIds"
                placeholder="(Все)"
                multiple
                v-on:valueChangedDebounced="changeFilter"
              />
            </th>
            <th class="filter">
              <FilterInput
                type="select2"
                :select2-options="recipientList"
                :select2-settings="select2SettingsMulti"
                v-model="filter.recipientOrgIds"
                placeholder="(Все)"
                multiple
                v-on:valueChangedDebounced="changeFilter"
              />
            </th>
            <th class="filter">
              <FilterInput
                type="select2"
                :select2-options="statusList"
                :select2-settings="select2SettingsMulti"
                v-model="filter.statusIds"
                placeholder="(Все)"
                multiple
                v-on:valueChangedDebounced="changeFilter"
              />
            </th>
            <th class="filter">
              <div class="range">
                <FilterInput
                  type="date"
                  v-model="filter.createdFrom"
                  class="mr-2"
                  placeholder="от"
                  v-on:valueChangedDebounced="changeFilter()"
                />
                <FilterInput
                  type="date"
                  v-model="filter.createdTo"
                  placeholder="до"
                  v-on:valueChangedDebounced="changeFilter()"
                />
              </div>
            </th>
            <th class="filter">
              <div class="range">
                <FilterInput
                  type="date"
                  v-model="filter.answerDeadlineFrom"
                  class="mr-2"
                  placeholder="от"
                  v-on:valueChangedDebounced="changeFilter()"
                />
                <FilterInput
                  type="date"
                  v-model="filter.answerDeadlineTo"
                  placeholder="до"
                  v-on:valueChangedDebounced="changeFilter()"
                />
              </div>
            </th>
            <th class="filter">
              <FilterInput v-model="filter.body" placeholder="Текст" v-on:valueChangedDebounced="changeFilter()" />
            </th>
            <th class="filter">
              <FilterInput
                type="select2"
                :select2-options="proposalList"
                :select2-settings="select2SettingsMulti"
                v-model="filter.proposalIds"
                placeholder="(Все)"
                multiple
                v-on:valueChangedDebounced="changeFilter"
              />
            </th>
            <th class="filter"></th>
          </tr>
        </thead>
        <tbody v-if="page?.items?.length && !isLoading">
          <tr v-for="item in page.items" :key="item.id">
            <td v-if="!eventId">{{ item.event.name }}</td>
            <td>{{ item.recipientOrg?.name || item.recipientDept?.name || item.email }}</td>
            <td class="request-status" :class="item.status.cssClass">{{ item.status.name }}</td>
            <td>{{ $momentFormat(item.created, 'DD MMMM YYYY HH:mm') }}</td>
            <td>{{ $momentFormat(item.answerDeadline, 'DD MMMM YYYY') }}</td>
            <td>{{ item.body }}</td>
            <td>{{ item.proposals.map((p) => p.requestType.name).join('; ') }}</td>
            <td>
              <router-link :to="`/preparation/requests/view/${item.id}`">Перейти</router-link>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="isLoading">
          <tr>
            <td class="text-center" colspan="7">Загрузка...</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" colspan="7">Запросов пока не поступало.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="page.pagesTotal > 1">
      <Pagination
        :page-count="page.pagesTotal"
        :current-page="page.pageNumber"
        @change="onPageChange($event, false)"
        @more="onPageChange($event, true)"
      />
    </div>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import FilterInput from '@/common/components/FilterInput';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';

  import DeptApi from '../../admin/api/department';
  import OrgApi from '../../admin/api/organization';
  import NsiApi from '../../nsi/api/nsi';
  import API from '../api/event';

  const recipientType = {
    foiv: 1,
    minec: 2,
    businness: 3,
  };

  export default {
    name: 'EventRequestTable',
    props: {
      eventId: Number,
    },
    components: { FilterInput, DefaultFilter },
    mixins: [filtermanager],
    data() {
      return {
        requestParams: {
          pageNumber: 1,
          pageSize: 10,
        },
        filter: {},
        page: {},
        isLoading: false,

        // isLoadingDict: false,
        eventList: [],
        recipientList: [],
        statusList: [],
        proposalList: [],
        select2SettingsMulti: Constants.select2SettingsMultiple,
        defaultRequest: {
          pageNumber: 1,
          pageSize: 0,
          isActive: true,
        },
      };
    },
    created() {
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
      });
    },
    methods: {
      loadPage(append) {
        this.isLoading = !append;
        let params = Object.assign({}, Object.assign(this.requestParams, this.filter));

        params.recipientOrgIds = [];
        params.recipientDeptIds = [];
        if (this.filter.recipientOrgIds) {
          this.filter.recipientOrgIds.forEach((x) => {
            var type = Number(x.split(';')[1]);
            var id = Number(x.split(';')[0]);

            if (type == recipientType.minec) params.recipientDeptIds.push(id);
            else params.recipientOrgIds.push(id);
          });
        }

        API.getRequests(params)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error?.response?.data);
          });
      },
      // ensurePage() {
      //   if (!this.page?.items?.length) {
      //     this.filter.eventIds = [this.eventId];
      //     if (!this.isLoadingDict) this.loadDictionaries();
      //     this.loadPage();
      //   }
      // },
      /**@public */
      // используется в родителе EventRequests.vue
      loadDictionaries() {
        if (!this.eventId) {
          //загружаем мероприятия
          API.getEventListWithRequest({})
            .then((response) => {
              this.eventList = response.data;
            })
            .catch((error) => {
              this.isLoading = false;
              console.error(error?.response?.data);
            });
        }
        NsiApi.search('mpkproposalrequesttype', this.defaultRequest)
          .then((response) => {
            this.proposalList = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        NsiApi.search('mpkRequestStatus', this.defaultRequest)
          .then((response) => {
            this.statusList = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });

        this.recipientList = [];

        const requestFoiv = Object.assign(
          { typeIds: [Constants.organizationType.foiv, Constants.organizationType.external] },
          this.defaultRequest,
        );
        OrgApi.searchShortFormat(requestFoiv)
          .then((response) => {
            response.data.items
              .map((x) => ({ text: x.name, id: x.id + ';' + recipientType.foiv }))
              .forEach((x) => this.recipientList.push(x));
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });

        const requestDept = Object.assign({ organizationIds: [Constants.organizationMinekId] }, this.defaultRequest);
        DeptApi.search(requestDept)
          .then((response) => {
            response.data.items
              .map((x) => ({ text: x.name, id: x.id + ';' + recipientType.minec }))
              .forEach((x) => this.recipientList.push(x));
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });

        // this.isLoadingDict = true;
      },
      onPageChange(pageNumber, addToExist) {
        this.requestParams.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      changeFilter() {
        this.requestParams.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .request-status {
    &.yellow {
      color: #eae000;
    }
    &.green {
      color: #31bc00;
    }
    &.red {
      color: #eb5757;
    }
  }
</style>
