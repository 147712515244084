<template>
  <div class="container-page">
    <p class="activity-schedule__title title-inner">Запросы</p>
    <EventRequestTable ref="eventTable" />
  </div>
</template>

<script>
  import EventRequestTable from '../components/EventRequestTable';
  export default {
    name: 'EventRequests',
    components: { EventRequestTable },
    data() {
      return {};
    },
    mounted() {
      this.$refs.eventTable.loadDictionaries();
      this.$refs.eventTable.loadPage(false);
    },
  };
</script>

<style scoped></style>
